import Services from './services.service'
import axios from '@/plugins/axios'
import { disableInterceptorOk } from '@/utils/HeaderNoInterceptors'

class FormulariosService extends Services {
  contacto(form: object): Promise<Object> {
    const promise = axios.post('front/contacto', form, disableInterceptorOk)
    return promise.then((res) => {
      return res.data
    })
  }

  testDrive(form: any): Promise<{ codigoReserva: string }> {
    if (form.acompanianteUno?.nombreApellido === '') {
      form.acompanianteUno = undefined
    }

    if (form.acompanianteDos?.nombreApellido === '') {
      form.acompanianteDos = undefined
    }

    if (form.acompanianteTres?.nombreApellido === '') {
      form.acompanianteTres = undefined
    }

    const promise = axios.post('front/reservar', form, disableInterceptorOk)
    return promise.then((res) => {
      return res.data
    })
  }

  recital(form: any): Promise<{ codigoReserva: string }> {
    if (form.vip?.imagenCedulaFrente === '') {
      form.vip.imagenCedulaFrente = undefined
    }

    if (form.vip?.imagenCedulaDorso === '') {
      form.vip.imagenCedulaDorso = undefined
    }
    const promise = axios.post('front/reservar/recital', form, disableInterceptorOk)
    return promise.then((res) => {
      return res.data
    })
  }

  driving(form: object): Promise<{ codigoReserva: string }> {
    const promise = axios.post(
      'front/contactodrivingtest',
      form,
      disableInterceptorOk
    )
    return promise.then((res) => {
      return res.data
    })
  }

  async stellantisValidacion(vin: string, documento: string) {
    const { data } = await axios.post<{ data: boolean }>('front/validacion/stellantis', { vin, documento }, disableInterceptorOk)
    return Boolean(data)
  }

  async checkTestdrive(turnoId: number, email: any) {
    const { data } = await axios.post<number>(`front/validacion/test-drive/${turnoId}`, { email }, disableInterceptorOk)
    return data
  }

}

export const formulariosService = new FormulariosService()
