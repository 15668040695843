
import { defineComponent, reactive, onMounted, ref } from 'vue'
import AppInfoTitles from '@/components/AppInfoTitles.vue'
import AppButtonContinue from '@/components/buttons/AppButtonContinue.vue'
import AppButtonReturn from '@/components/buttons/AppButtonReturn.vue'
import AppDisclaimer from '@/components/AppDisclaimer.vue'

import { useI18n } from 'vue-i18n'
import { Form, useForm } from 'vee-validate'
//import router from '@/router'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import router from '@/router'
import PersonalDate from '@/views/datos/part/PersonalDate.vue'
import CompanionDetails from '@/views/datos/part/CompanionDetails.vue'
import Documentation from '@/views/datos/part/Documentation.vue'
import TermsAndConditions from '@/views/datos/part/TermsAndConditions.vue'
import { formulariosService } from '@/services/formularios.service'
import useLoading from '@/composables/useLoading'

export default defineComponent({
  components: {
    TermsAndConditions,
    Documentation,
    CompanionDetails,
    PersonalDate,
    Form,
    AppInfoTitles,
    AppButtonContinue,
    AppButtonReturn,
    AppDisclaimer
  },
  setup() {
    const { t } = useI18n()
    const { state, commit } = useStore<VuexStore>(key)
    const { loading, setSearch } = useLoading(false)
    const init = ref(true)

    window.scrollTo(0, 0)

    const totalTurnos = ref<number | null>(null)

    const form = reactive({
      personal: {},
      documentacion: {},
      acompanianteUno: state.navigation.actividad?.aceptaAcompaniantes
        ? {}
        : undefined,
      acompanianteDos: state.navigation.actividad?.aceptaAcompaniantes
        ? {}
        : undefined,
      acompanianteTres: state.navigation.actividad?.aceptaAcompaniantes
        ? {}
        : undefined,
      crearCuenta: false,
      terminos: {},
      interesadoId: state.navigation.preDatos?.interesadoId
    })

    const { handleSubmit } = useForm()

    const onSubmit = handleSubmit(async () => {
      try {
        setSearch(true, 'Estamos procesando tu reserva...')

        const { codigoReserva } = await formulariosService.testDrive(form)
        await commit('COMPLETE_DATOS_PERSONALES', form)
        await commit('SET_RESERVA', codigoReserva)
        await commit('SEND_FORM_COMPLETE')

        await router.push({ name: 'Solicitudes_testdrive_confirmacion' })
      } catch (error) {
        setSearch(false, '')
      }
    })

    const onInvalidSubmit = () => {
      // Desplázate al primer error
      const firstErrorElement = document.querySelector('.input-error')
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }

    onMounted(async () => {
      totalTurnos.value = await formulariosService.checkTestdrive(
        state.navigation.turno?.id ?? 0,
        state.navigation.preDatos?.email ?? ''
      )
      init.value = false
    })

    return {
      onSubmit,
      t,
      form,
      loading,
      navigation: state.navigation,
      onInvalidSubmit,
      totalTurnos,
      init
    }
  }
})
